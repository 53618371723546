import React from 'react'
import {
  CFView,
  CFImage,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { mobilePromotion, promotions, promoParallax } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFImage src={mobilePromotion} w="100%" alt="Sushi Town Map" />
      </MobileScreen>
      <DefaultScreen>
        <CFView column center w="100%" maxWidth="1400px">
          <CFImage
            src={promotions}
            w="100%"
            maxWidth="1200px"
            alt="Sushi Town Map"
          />
          <CFView
            h="550px"
            w="100%"
            image={`url(${promoParallax}) center/ cover no-repeat`}
            style={{ backgroundAttachment: 'fixed' }}
            zIndex={90}
            column
            justifyCenter
            alignStart
          ></CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
