import React from 'react'
import { Parallax } from 'react-parallax'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  aboutBurnaby,
  aboutNorthVan,
  aboutParallax,
  mobileAboutBurnaby,
  mobileAboutNorthVan,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let AboutMobileImage = null
        let AboutMobileImage2 = null
        let AboutImage = null
        if (locationId === 'kpz4oKyxRZ7Te8bKslAe') {
          AboutImage = aboutNorthVan
          AboutMobileImage = mobileAboutNorthVan
        } else {
          AboutImage = aboutNorthVan
          AboutMobileImage = mobileAboutNorthVan
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView column center>
                <CFImage
                  src={AboutMobileImage}
                  w="100%"
                  alt="aboutImageMobile"
                />
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView column center w="100%" maxWidth="1400px" m="0 auto">
                <CFImage
                  src={AboutImage}
                  mt="20px"
                  w="100%"
                  alt="about Image"
                />
                <CFView
                  h="550px"
                  w="100%"
                  image={`url(${aboutParallax}) center/ cover no-repeat`}
                  style={{ backgroundAttachment: 'fixed' }}
                  zIndex={90}
                  column
                  justifyCenter
                  alignStart
                ></CFView>
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
