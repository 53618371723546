import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import ViewMenuButton from './ViewMenuButton'
import {
  hero,
  heroText,
  mobileHero,
  mobileHeroText,
  viewMenuButton,
  sushiTownNVMenu,
  sushiTownBBYMenu,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView column justifyCen alignCenter mt="25%" h="87vh">
            <CFImage src={mobileHeroText} w="85%" alt="mobile hero text" />
            <CFView column center>
              <CFView pulsate mt="35px">
                <OrderPickupButton />
              </CFView>
              <CFView mt="35px">
                <ViewMenuButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView w="100%" h="600px" column center pb="5%">
            <CFView bold style={{ fontSize: 47 }} white>
              NOW TAKING ONLINE ORDERS
            </CFView>
            <CFView
              style={{ fontSize: 28 }}
              white
              mt="15px"
              w="45vw"
              maxWidth="750px"
              textCenter
            >
              Sushi Town is now taking online orders! Get your favourite Japense
              food to go now!
            </CFView>
            <CFView column center>
              <CFView mt="35px" pulsate>
                <OrderPickupButton />
              </CFView>
              <CFView mt="15px">
                <ViewMenuButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
