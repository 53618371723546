import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  locationMapBurnaby,
  locationMapNorthVan,
  mobileMapBurnaby,
  mobileMapNorthVan,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let locationLink = ''
        let mapImageMobile = null
        let mapImage = null
        if (locationId === 'kpz4oKyxRZ7Te8bKslAe') {
          locationLink = 'https://goo.gl/maps/pLtusCWAujMW1new6'
          mapImage = locationMapNorthVan
          mapImageMobile = mobileMapNorthVan
        } else {
          locationLink = 'https://goo.gl/maps/pLtusCWAujMW1new6'
          mapImage = locationMapNorthVan
          mapImageMobile = mobileMapNorthVan
        }
        return (
          <CFView>
            <MobileScreen>
              <CFLink href={locationLink}>
                <CFImage src={mapImageMobile} w="100%" alt="Sushi Town Map" />
              </CFLink>
            </MobileScreen>
            <DefaultScreen>
              <CFLink href={locationLink}>
                <CFImage src={mapImage} w="100%" alt="Sushi Town Map" />
              </CFLink>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
