import React from 'react'
import {
  CFView,
  CFImage,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { foodGallery, mobileGallery } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileGallery} w="100%" alt="Food Gallery" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1100px" m="30px auto">
          <CFImage src={foodGallery} w="100%" alt="Food Gallery" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
