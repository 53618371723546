export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1585671918/cafesaladedefruits/saladedefruits_instagram.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1591377852/general/cflogo_black.png'
export const orderDeliveryButton = ''
export const locationMapFraser =
  'https://res.cloudinary.com/codefusionlisa/image/upload/f_auto,q_auto:best/v1607738637/samuraisushi/locationMapFraser.jpg'
export const logoWhite =
  'https://res.cloudinary.com/codefusionlisa/image/upload/f_auto,q_auto:best/v1606983617/samuraisushi/logoWhite.png'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusionlisa/image/upload/f_auto,q_auto:best/v1606983203/samuraisushi/mobileContactbg.jpg'
export const aboutBurnaby =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610411390/sushitown/aboutBurnaby.jpg'
export const aboutNorthVan =
  'https://d2who2d4kp3xdw.cloudfront.net/aboutNorthVan.webp'
export const aboutParallax =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610411390/sushitown/aboutParallax.jpg'
export const contactbg =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610411390/sushitown/contactbg.jpg'
export const foodGallery =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1612411483/sushitown/foodGallery2.jpg'
export const hero =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1611620014/sushitown/hero2.jpg'
export const heroText =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1611620014/sushitown/heroText2.png'
export const locationMapBurnaby =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610411616/sushitown/locationMapBurnaby.jpg'
export const locationMapNorthVan =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610411390/sushitown/locationMapNorthVan.jpg'
export const logo =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610411390/sushitown/logo.png'
export const logoBlack =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610411390/sushitown/logoBlack.png'
export const mobileAboutBurnaby =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610411390/sushitown/mobileAboutBurnaby.jpg'
export const mobileAboutNorthVan =
  'https://d2who2d4kp3xdw.cloudfront.net/mobileAboutNorthVan.webp'
export const mobileHero =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1611620525/sushitown/mobileHero.jpg'
export const mobileHeroText =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610411390/sushitown/mobileHeroText.png'
export const mobileGallery =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1612411483/sushitown/mobileGallery2.jpg'
export const mobileMapBurnaby =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610411390/sushitown/mobileMapBurnaby.jpg'
export const mobileMapNorthVan =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610411390/sushitown/mobileMapNorthVan.jpg'
export const mobilePromotion =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610411390/sushitown/mobilePromotion.jpg'
export const orderPickupButton =
  'https://res.cloudinary.com/cforder/image/upload/v1610411390/sushitown/orderPickupButton.png'
export const promotions =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610411390/sushitown/promotions.jpg'
export const promoParallax =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610411390/sushitown/promoParallax.jpg'
export const viewMenuButton =
  'https://res.cloudinary.com/cforder/image/upload/v1612411483/sushitown/viewMenuButton.png'
export const sushiTownNVMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1679708444/sushitown/sushiTownNV03242023.pdf'
export const sushiTownBBYMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1691782087/sushitown/sushiTownBBY082023.pdf.pdf'
export const interior = 'https://d2who2d4kp3xdw.cloudfront.net/interior2.jpeg'
